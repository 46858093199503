'use client';

import { usePathname } from 'next/navigation';
import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';
import AppLoadingComponent from '@/shared/components/AppLoadingComponent';
import { COOKIES_NAME } from '@/shared/constants/app';
import { ROUTES } from '@/shared/constants/routes';
import { useLoadingStore } from '@/shared/stores/loadingStore';
import { saveCookie } from '@/shared/utils/user-session.util';
export default function Template({
  children
}: {
  children: React.ReactNode;
}) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // staleTime: Infinity,
        onSuccess: () => {},
        onError: () => {}
      }
    }
  });
  const pathname = usePathname();
  const {
    loading: appLoading
  } = useLoadingStore();
  useEffect(() => {
    if (!Object.values(ROUTES.auth).includes(pathname)) {
      saveCookie(COOKIES_NAME.requestUrl, pathname);
    }
  }, [pathname]);
  return <>
            <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="template.tsx">
                {children}
                <ToastContainer draggable style={{
        zIndex: 999999
      }} data-sentry-element="ToastContainer" data-sentry-source-file="template.tsx" />

                {appLoading && <AppLoadingComponent />}

                <ReactQueryDevtools initialIsOpen={false} data-sentry-element="ReactQueryDevtools" data-sentry-source-file="template.tsx" />
            </QueryClientProvider>
        </>;
}